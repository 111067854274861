import {
  NftItems,
  ProjectNftQuery,
  UserCollectionNftQuery,
} from "../selector/nft";
import { requester } from "../utils/requester";

export async function getProjectNfts(projectId: string): Promise<NftItem[]> {
  const res = await requester.get(`/nft/${projectId}`);
  return res.data as NftItem[];
}

export async function getNft(
  projectId: string,
  tokenId: string
): Promise<NftItem> {
  const res = await requester.get(`/nft/${projectId}/item/${tokenId}`);
  return res.data;
}

export async function getNftHistory(
  projectId: string,
  tokenId: string
): Promise<NftLog[]> {
  const res = await requester.get(`/nft/${projectId}/item/${tokenId}/history`);
  return res.data;
}

export async function getNftsByWalletAddress(
  walletAddress: string
): Promise<NftItem> {
  const res = await requester.get(`/nft/${walletAddress}/collections`);
  return res.data;
}

export async function getWalletNfts(
  nftQuery: UserCollectionNftQuery
): Promise<NftItem[]> {
  const { walletAddress, sortAndSearchOption } = nftQuery;
  console.log("nftQuery", nftQuery);
  const res = await requester.get(`/profile/${walletAddress}/nft`, {
    params: {
      orderBy: sortAndSearchOption?.orderBy,
      order: sortAndSearchOption?.order,
      searchQuery: sortAndSearchOption?.searchQuery,
    },
  });
  return res.data as NftItem[];
}

export async function getNftsByQuery(
  nftQuery: ProjectNftQuery
): Promise<NftItems> {
  console.log(nftQuery);
  const { projectId, sortAndSearchOption } = nftQuery;
  const res = await requester.get(`/nft/${projectId}`, {
    params: {
      orderBy: sortAndSearchOption?.orderBy,
      order: sortAndSearchOption?.order,
      searchQuery: sortAndSearchOption?.searchQuery,
    },
  });
  return res.data as NftItems;
}
