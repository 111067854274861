import { Link, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import { walletState } from "../../common/states";
import { walletNftItemsSelector } from "../../common/selector/nft";
import NftCard from "../../components/NftCard.tsx";
import { SortAndSearchOption } from "./UserCollectionSortAndSearch";

type NftListProps = {
  sortAndSearchOption: SortAndSearchOption;
  setItemCount: (val: number) => void
};

const NftListContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
`;

const UserCollectionNftList = ({ sortAndSearchOption, setItemCount }: NftListProps) => {
  const wallet = useRecoilValue(walletState);
  const walletAddress: string = wallet.address || '';
  const projectNfts = useRecoilValue(walletNftItemsSelector({ walletAddress, sortAndSearchOption }));
  setItemCount(projectNfts.length);

  return (
    <NftListContainer>
      {projectNfts.map((nftItem) => (
        <Link to={`/nft-market/project/${nftItem.projectId}/nft/${nftItem.tokenId}`} key={nftItem.tokenId}>
          <NftCard nftItem={nftItem} key={nftItem.tokenId} />
        </Link>
      ))}
    </NftListContainer>
  );
};

export default UserCollectionNftList;
