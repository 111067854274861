import { Col, InputNumber, Modal as AntModal, Row, Select } from "antd";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useRecoilRefresher_UNSTABLE, useRecoilState, useRecoilValue } from "recoil";
import styled from "styled-components";
import { pendingState, walletState } from "../../common/states";
import { sleep } from "../../common/utils/functools";
import { cancelSale, putOnSale } from "../../common/utils/market";
import { nftSelector, nftHistorySelector } from "../../common/selector/nft";

const { Option } = Select;

type CancelSellModalProps = {
  nftItem: NftItem,
  visible: boolean,
  toggleVisible: () => void,
}

const Modal = styled(AntModal)``;

const CancelSellModal = ({ nftItem, visible, toggleVisible }: CancelSellModalProps) => {
  const { projectId = "", tokenId = "" } = useParams();
  const [price, setPrice] = useState(0);
  const walletInfo = useRecoilValue(walletState);
  const [pending, setPending] = useRecoilState(pendingState);
  const refresher = useRecoilRefresher_UNSTABLE(nftSelector({
    projectId,
    tokenId,
  }));

  const refreshNftHistory = useRecoilRefresher_UNSTABLE(
    nftHistorySelector({
      projectId,
      tokenId,
    })
  );


  return <Modal visible={visible} title={`Cancel Sell ${nftItem.metadata.name}`} onCancel={() => toggleVisible()} onOk={async () => {
    await cancelSale(nftItem, walletInfo);
    setPending(true);
    await sleep(3000);
    setPending(false);
    toggleVisible();
    refresher();
    refreshNftHistory();
  }}>
    <Row>
      Cancel selling {nftItem.metadata.name}?
    </Row>
  </Modal>
}

export default CancelSellModal;