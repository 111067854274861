import { createGlobalStyle } from "styled-components";
import { BACKGROUND, GRAY } from "../common/styles";

export const Styles = createGlobalStyle`
  html,
  body {
    padding: 0;
    margin: 0;
    font-size: 62.5%;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;

    @media only screen and (max-width: 1610px) {
      font-size: 56%;
    }

    @media only screen and (max-width: 1440px) {
      font-size: 50%;
    }
    @media only screen and (max-width: 992px) {
      font-size: 58%;
    }

    @media only screen and (max-width: 768px) {
      font-size: 50%;
    }
    background-color: rgb(17 16 16);
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  * {
    box-sizing: border-box;
  }

  h1 {
    font-size: 4rem;
  }

  h2 {
    font-size: 3rem;
  }

  h4 {
    font-size: 2rem;
    color: ${GRAY};
  }

  button {
    cursor: pointer;
  }
`;
