import styled from 'styled-components';
import { CloseOutlined, MenuOutlined } from '@ant-design/icons';
import { Menu } from 'antd';

type NavItemProps = {
  upcoming?: boolean;
};

export const NavArea = styled('div')`
  width: 100%;
  height: 8vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(8, 8, 8, 80%);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
`;

export const NavContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 10%;
`;

export const NavLeft = styled('div')`
  img {
    width: 170px;
    height: 70px;
  }
`;

export const NavRight = styled('div')`
  width: 90%;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const NavItem = styled(Menu.Item)<NavItemProps>`
  cursor: pointer;
  margin: 0 1%;
  transition: 0.3s linear;
  color: #727283;
  &:hover {
    color: #000000;
  }

  ${props => props.upcoming && `
    cursor: not-allowed;
    pointer-events: none;
  `}

`;

export const NavSubMenu = styled(Menu.SubMenu)`
  cursor: pointer;
  margin: 0 3%;
  transition: 0.3s linear;
  color: #727283;
  &:hover {
    color: #000000;
  }
`

export const UpcomingLabel = styled.img`
  width: 60px;
  position: absolute;
  transform: translate(-50%, -50%);  // ensure it's centered
`

export const NavWalletButton = styled('button')`
  display: inline-block;
  width: 200px;
  height: 47px; 
  border: 0;
  font-size: 16px;
  border-radius: 1rem;
  cursor: pointer;
  font-weight: 600;
  color: white;
  background: #0A54F6;

  img {
    width:30px;
    height: 30px;
    margin-right: 17px;
  }
  @media only screen and (max-width: 890px) {
    width: 200px;
  }
`;

export const NavProfileMenu = styled(Menu)`
  @media only screen and (max-width: 890px) {
    margin-top: 20px;
  }
  & .ant-dropdown-menu-item {
    padding: 0;
  }
`;

export const NavProfileArea = styled('div')`
  @media only screen and (max-width: 890px) {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`

export const NavProfileButton = styled('button') <any>`
  display: inline-block;
  width: 48px;
  height: 48px;
  border: 0;
  font-size: 2rem;
  border-radius: 100%;
  cursor: pointer;
  background-size: cover;
  background-image: url(${(props) => props.imageUrl || 'img/profile-example.webp'});

  @media only screen and (max-width: 890px) {
    width: 62px;
    height: 62px;
  }
`;

export const NavItemDisabled = styled(NavItem)`
  cursor: not-allowed;
  color: #a4a4a4;
  &:hover {
    color: #a4a4a4;
  }
`;

export const DesktopNavWrapper = styled(NavRight)`
  width: 100%;
  @media only screen and (max-width: 890px) {
    display: none;
  }
`;

export const NavItemContainer = styled('ul')`
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 890px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const NavMenu = styled('nav')`
  margin-top: 2rem;
  width: 100%;

  ul {
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
    font-family: 'Roboto';
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    padding-inline-start: 0px;
    line-height: 1.5;
    letter-spacing: normal;
    color: #000;
    padding-right: 5%;
    text-align: right;
    list-style-position: inside;

    ${NavItem} {
      display: inline-flex;
      button {
        background: none;
        border: 0;
        display: inherit;
        justify-content: inherit;
        margin: inherit;
        font-size: inherit;
        font-weight: inherit;
        font-stretch: inherit;
        font-style: inherit;
        padding-inline-start: inherit;
        line-height: inherit;
        letter-spacing: inherit;
        text-align: inherit;
        color: inherit;
      }
    }

    ${NavItemDisabled} {
      display: inline-flex;
      button {
        background: none;
        border: 0;
        display: inherit;
        justify-content: inherit;
        margin: inherit;
        font-size: inherit;
        font-weight: inherit;
        font-stretch: inherit;
        font-style: inherit;
        padding-inline-start: inherit;
        line-height: inherit;
        letter-spacing: inherit;
        text-align: inherit;
        color: inherit;
      }
    }
  }
`;

export const NavIcons = styled('nav')`
  height: 100%;
  margin-top: 5px;

  ul {
    display: flex;
    padding-inline-start: 0px;
    margin: 0px;

    li {
      display: inline-flex;
    }
  }

  figure {
    width: 24px;
    height: 20.4px;
    @include figureInit();

    img {
      margin-top: 4px;
      width: 20px;
    }
  }

  li:not(:last-child) {
    margin-right: 20px;
  }
`;

export const Outline = styled(MenuOutlined) <any>`
  font-size: 22px;
  svg {
    fill: #000;
  }
`;

export const NavCloseOutlined = styled(CloseOutlined)`
  font-size: 3rem;
  font-weight: bold;
  svg {
    fill: #fff;
  }
`;

export const Burger = styled('div')`
  margin-left: auto;
  margin-right: 0;
  @media only screen and (max-width: 890px) {
    display: block;
  }
  display: none;
  svg {
    fill: #000;
  }
`;
export const MenuTitle = styled('h5')`
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
`;
export const Label = styled('span')`
  font-weight: 500;
  color: #404041;
  text-align: right;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

export const MobileNavWrapper = styled('div')`
  ${NavCloseOutlined} {
    margin-left: auto;
    margin-right: 0;
    margin-bottom: 10px;
  }

  ul {
    margin-bottom: 50px;
  }
  ${NavItem} {
    display: block;
    color: #fff;
    font-size: 1.8rem;
    font-weight: normal;
    margin-bottom: 30px;
  }
  ${NavItemDisabled} {
    display: block;
    color: #a4a4a4;
    font-size: 1.8rem;
    font-weight: normal;
    margin-bottom: 30px;
  }
  ${NavIcons} {
    figure {
      width: 40px;
      margin-right: 20px;
      img {
        margin-top: 4px;
        width: 40px;
      }
    }
  }
`;


export const ProfileNavItem = styled('div')`
  width: 200px;
  padding: 20px 10px;
  font-family: AppleSDGothicNeo;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #727283;
`