import { useRecoilValue, useRecoilRefresher_UNSTABLE } from "recoil";
import styled from "styled-components";
import { pebToIntegerFormat, convertTimeStampToDate, getTokenDecimal, numberFormat, convertDecimal } from "../../common/utils/functools"
import { NEUMORPH } from "../../common/styles";
import { nftHistorySelector } from "../../common/selector/nft";
import Table from "../../components/Table";
import type { ColumnsType } from "antd/es/table";
import NftBoxTitle from "../../common/NftBoxTitle";

type HistoryProps = {
  nftItem: NftItem;
};
interface HistoryDataType {
  key: string;
  price: any;
  unit: string;
  time: string;
}

const Container = styled.div`
  padding: 20px;
  margin-bottom: 32px;
  border-radius: 20px;
  border: 1px #00B4FF solid;
  color: white;
  
  
  .ant-table .ant-table-cell {
    border-color: #00B4FF !important;
    background: #0f0f0f !important;
    color: white;
  }

  .ant-table-thead {
    .ant-table-cell {
      color: #487AB9 !important;
    }
  }
`;

const History = ({ nftItem }: HistoryProps) => {
  const { projectId, tokenId } = nftItem;
  const nftHistory = useRecoilValue(
    nftHistorySelector({
      projectId,
      tokenId,
    })
  );

  const tableColumns: ColumnsType<HistoryDataType> = [
    {
      title: "Price",
      key: "price",
      dataIndex: "price",
    },
    {
      title: "Unit",
      key: "unit",
      dataIndex: "unit",
    },
    {
      title: "Time",
      key: "time",
      dataIndex: "time",
    },
  ];

  return (
    <Container>
      <NftBoxTitle>Price History</NftBoxTitle>
      <Table
        colums={tableColumns}
        dataSource={nftHistory.filter(item => item.action === 'SaleMatched').map((item, idx) => {
          const data = JSON.parse(item.data);
          const decimal = getTokenDecimal(data.ticker);
          return {
            key: idx,
            price: data.price ? numberFormat(parseFloat(convertDecimal(data.price, decimal))) : '',
            unit: JSON.parse(item.data).ticker,
            time: convertTimeStampToDate(item.timestamp),
          };
        })}
      />
    </Container>
  );
};

export default History;
