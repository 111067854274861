import { atom, selector } from "recoil";

export const walletState = atom<WalletInfo>({
  key: "walletState",
  default: {
    type: 'kaikas',
  },
});

export const pendingState = atom<boolean>({
  key: 'pendingState',
  default: false,
})