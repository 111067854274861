import { Link, useParams } from "react-router-dom";
import { Dispatch, SetStateAction } from "react";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import { nftItemsQuerySelector } from "../../common/selector/nft";
import NftCard from "../../components/NftCard.tsx";
import { SortAndSearchOption } from "./SortAndSearch";

type NftListProps = {
  projectInfo: NftProject;
  sortAndSearchOption: SortAndSearchOption;
  setItemCount: (val: number) => void;
};

const NftListContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
`;

const NftList = ({ sortAndSearchOption, setItemCount, projectInfo }: NftListProps) => {
  const { projectId = "" } = useParams();
  console.log(sortAndSearchOption, "sortAndSearchOption");
  const projectNfts = useRecoilValue(
    nftItemsQuerySelector({
      projectId,
      sortAndSearchOption,
    })
  );

  setItemCount(projectNfts.total);

  return (
    <NftListContainer>
      {projectNfts.nftItems.slice(0, 250).map((nftItem) => (
        <Link to={`./nft/${nftItem.tokenId}`}>
          <NftCard 
            nftItem={nftItem} 
            key={nftItem.tokenId} 
            projectName={projectInfo.title}
          />
        </Link>
      ))}
    </NftListContainer>
  );
};

export default NftList;
