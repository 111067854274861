import React from "react";
import { useRoutes } from "react-router-dom";
import Home from "../pages/Home";
import MintPage from "../pages/Mint";
import LaunchpadPage from "../pages/Launchpad";
import Profile from "../pages/Profile";
import NftProjects from "../pages/NftProjects";
import NftPage from "../pages/NftPage";
import ProjectMarket from "../pages/ProjectMarket";

const RouterConfig = (): JSX.Element => {
  const mainRoutes = {
    path: "/",
    element: <Home />,
    children: [
      // {path: '*', element: <Navigate to='/404' />},
      { path: "*", element: <Home key={Math.random()} /> },
      { path: "home", element: <Home key={Math.random()} /> },
    ],
  };
  const subRoutes = [
    { path: "/mint", element: <MintPage /> },
    { path: "/launchpad/:category", element: <LaunchpadPage key={Math.random()} />},
    { path: "/launchpad/project/:projectId", element: <MintPage key={Math.random()} />},
    {
      path: "/nft-market/:category",
      element: <NftProjects key={Math.random()} />,
    },
    {
      path: "/nft-market/project/:projectId",
      element: <ProjectMarket key={Math.random()} />,
    },
    {
      path: "/nft-market/project/:projectId/nft/:tokenId",
      element: <NftPage key={Math.random()} />,
    },
    { path: "/profile/collections", element: <Profile key={Math.random()} /> },
  ];

  return <>{useRoutes([...subRoutes, mainRoutes])}</>;
};

export default RouterConfig;
