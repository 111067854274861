import React from "react";
import styled from "styled-components";
import { HomeBannerContainer } from "./styles";

const HomeBanner = () => {
  return (
    <HomeBannerContainer>
      <BannerContainer
      >
        <BannerTitle>
          <img
            src="/img/visual_bgTitle.webp"
            style={{ maxWidth: "100%" }}
          />
        </BannerTitle>
      </BannerContainer>
    </HomeBannerContainer>
  );
};

const BannerContainer = styled.div`
  background-image: url("/img/visual_bg.webp");
  height: 45vw;
  max-height: 800px;
  background-size: cover;
  display: flex;
  align-items: center;
`;

const BannerTitle = styled.div`
  width: 30%;
  max-width: 800px;
  margin-left: 10%
`

export default HomeBanner;
