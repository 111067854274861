import { numberFormat, pebToFloatFormat } from '../../common/utils/functools';
import { Row, Col, Statistic } from 'antd';
import KlayIcon from '../../common/KlayIcon';
import TetherIcon from '../../common/TetherIcon';
import styled from "styled-components";

interface ProjectDashboardProps {
  totalSupplyCount?: number,
  floorPrice?: number,
  tradedVolume?: number,
  createdDate?: string,
}

const DashboardRow = styled(Row)`
  width: 700px;
  margin-top: 50px;
  margin-bottom: 20px;
  @media only screen and (max-width: 390px) {
    width: 350px;
  }
`;

//TODO: css, styled apply
export const ProjectDashboard = ({ totalSupplyCount, floorPrice, tradedVolume, createdDate }: ProjectDashboardProps) => {
  return (
    <DashboardRow className="nft-project-stat" gutter={15}>
      <Col span={6} style={{ textAlign: "center" }}>
        <div className="ant-statistic">
          <div className="ant-statistic-title" style={{color: "white"}}>Total Items</div>
          <div className="ant-statistic-content">
            <span style={{color: "#3A9CF5"}}>
              {numberFormat(totalSupplyCount || 0)}
            </span>
          </div>
        </div>
      </Col>
      <Col span={6} style={{ textAlign: "center" }}>
        {/* <Statistic title={'Floor Price'} value={numberFormat(nftProject.floor_price || '0')} /> */}
        <div className="ant-statistic">
          <div className="ant-statistic-title" style={{color: "white"}}>Floor Price</div>
          <div className="ant-statistic-content">
            <span style={{color: "#3A9CF5"}}>
              {numberFormat(floorPrice || 0)} KLAY
            </span>
          </div>
        </div>
      </Col>
      <Col span={6} style={{ textAlign: "center" }}>
        {/* <Statistic title={'Volume Traded'} value={numberFormat(nftProject.traded_volume || '0')} /> */}
        <div className="ant-statistic">
          <div className="ant-statistic-title" style={{color: "white"}}>Created</div>
          <div className="ant-statistic-content">
            <span style={{color: "#3A9CF5"}}>{createdDate}</span>
          </div>
        </div>
      </Col>
      <Col span={6} style={{ textAlign: "center" }}>
        {/* <Statistic title={'Volume Traded'} value={numberFormat(nftProject.traded_volume || '0')} /> */}
        <div className="ant-statistic">
          <div className="ant-statistic-title" style={{color: "white"}}>Chain</div>
          <div className="ant-statistic-content">
            <span style={{color: "#3A9CF5"}}>Klaytn</span>
          </div>
        </div>
      </Col>
    </DashboardRow>
  );
};
