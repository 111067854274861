import styled from "styled-components";
import { FooterContainer, FooterTextArea } from "./styles"

const IconContainer = styled.div`
  display: inline-block;
`

const Footer = () => {
  return (
    <FooterContainer>
      <div style={{marginTop: "10px"}}>
        <img style={{
          width: "200px"
        }}
        src="/img/white-logo.png" />
      </div>
      <div style={{color: "white", fontSize:"10px", fontWeight: "bold"}}>
        <p>© 2022 Mr.Everything PTE. LTD.</p>
      </div>

      {/* <FooterTextArea>
        © Mr.everything, Inc. All rights reserved.
      </FooterTextArea>
      <FooterTextArea>
        <IconContainer>
          <svg width="36" height="36" viewBox="0 0 24 24" fill="#000" xmlns="http://www.w3.org/2000/svg"><path d="M16.5122 7.8375C15.6707 7.44625 14.7709 7.16191 13.8302 7C13.7147 7.20756 13.5797 7.48673 13.4867 7.70881C12.4867 7.55937 11.4959 7.55937 10.5143 7.70881C10.4213 7.48673 10.2832 7.20756 10.1667 7C9.23694 7.16191 8.33612 7.44729 7.4826 7.83957C5.78526 10.3883 5.32514 12.8738 5.5552 15.336C6.68097 16.1594 7.77197 16.6669 8.84456 16.999C9.10939 16.6368 9.34558 16.2518 9.54905 15.846C9.16153 15.6997 8.79037 15.5191 8.43967 15.3095C8.53271 15.361 8.62371 15.1694 8.71164 15.0957C10.8507 16.0899 13.1748 16.0899 15.2883 15.0957C15.3773 15.1694 15.4683 15.361 15.5603 15.3095C15.2086 15.5201 14.8364 15.7007 14.4489 15.847C14.6523 16.2518 14.8875 16.6378 15.1533 17C16.227 16.6679 17.319 16.1604 18.4447 15.336C18.7147 12.4836 17.9836 10.021 16.5122 7.8375ZM9.84047 13.8172C9.19835 13.8172 8.67176 13.2215 8.67176 12.4961C8.67176 11.7707 9.1871 11.1739 9.84047 11.1739C10.4939 11.1739 11.0204 11.7696 11.0092 12.4961C11.0102 13.2215 10.4939 13.8172 9.84047 13.8172ZM14.1595 13.8172C13.5174 13.8172 12.9908 13.2215 12.9908 12.4961C12.9908 11.7707 13.5061 11.1739 14.1595 11.1739C14.8129 11.1739 15.3394 11.7696 15.3282 12.4961C15.3282 13.2215 14.8129 13.8172 14.1595 13.8172Z"></path></svg>
        </IconContainer>
        <IconContainer>
          <a href="https://twitter.com/MrEverything721" target="_blank">
            <svg width="36" height="36" viewBox="0 0 24 24" fill="#000" xmlns="http://www.w3.org/2000/svg"><path d="M18.6076 8.73187C18.1513 8.93223 17.6607 9.06759 17.146 9.12849C17.6714 8.81676 18.075 8.32315 18.2649 7.73494C17.7732 8.02365 17.2285 8.23322 16.6488 8.34617C16.1846 7.85661 15.5232 7.55078 14.7912 7.55078C13.3858 7.55078 12.3663 8.67852 12.3663 10.0695C12.3663 10.267 12.2688 10.4592 12.3122 10.6436C10.1971 10.5385 8.32192 9.53573 7.06671 8.0118C6.84764 8.3838 6.72211 8.81646 6.72211 9.27809C6.72211 10.152 7.1714 10.923 7.85427 11.3746C7.43711 11.3616 7.04469 11.3682 6.70158 11.0596C6.70129 11.0701 6.70129 11.0807 6.70129 11.0913C6.70129 12.3116 7.57854 13.3296 8.74276 13.5612C8.52922 13.6188 8.30439 13.6495 8.0723 13.6495C7.90831 13.6495 7.74892 13.6337 7.59349 13.6043C7.91737 14.605 8.85719 15.3332 9.97087 15.3535C9.09989 16.0291 8.00259 16.4318 6.8102 16.4318C6.60478 16.4318 6.40222 16.4198 6.20312 16.3966C7.32939 17.1113 8.6671 17.5283 10.1043 17.5283C14.7853 17.5283 17.3452 13.6902 17.3452 10.3616C17.3452 10.2536 17.3427 10.1438 17.3378 10.0357C17.835 9.6806 18.2664 9.23702 18.6076 8.73187H18.6076Z"></path></svg>
          </a>
        </IconContainer>
        <IconContainer>
          <svg width="36" height="36" viewBox="0 0 24 24" fill="#000" xmlns="http://www.w3.org/2000/svg"><path d="M8.51256 8C11.0048 8 13.025 10.0148 13.025 12.5001C13.025 14.9853 11.0046 17 8.51256 17C6.02049 17 4 14.9853 4 12.5001C4 10.0148 6.02033 8 8.51256 8ZM15.7188 8.2636C16.9649 8.2636 17.9751 10.1601 17.9751 12.5001H17.9752C17.9752 14.8394 16.9651 16.7365 15.7189 16.7365C14.4728 16.7365 13.4627 14.8394 13.4627 12.5001C13.4627 10.1608 14.4727 8.2636 15.7188 8.2636ZM19.2065 8.70486C19.6446 8.70486 20 10.404 20 12.5001C20 14.5955 19.6448 16.2953 19.2065 16.2953C18.7681 16.2953 18.4131 14.596 18.4131 12.5001C18.4131 10.4042 18.7683 8.70486 19.2065 8.70486Z"></path></svg>
        </IconContainer>
        <IconContainer >
          <svg width="36" height="36" viewBox="0 0 24 24" fill="#000" xmlns="http://www.w3.org/2000/svg"><path d="M4.77702 11.5801L12.3266 8.45854C13.0718 8.13337 15.5992 7.09285 15.5992 7.09285C15.5992 7.09285 16.7656 6.63762 16.6684 7.74318C16.636 8.19841 16.3768 9.79171 16.1176 11.5151L15.3075 16.6202C15.3075 16.6202 15.3627 17.3681 14.6919 17.4981C14.1411 17.6282 13.2338 17.0429 13.0718 16.9128C12.9422 16.8153 10.6417 15.352 9.79927 14.6367C9.57366 14.4416 9.31336 14.0514 9.83167 13.5961C10.9981 12.5231 12.3914 11.1899 13.2338 10.3445C13.6227 9.9543 14.0115 9.04384 12.3914 10.1494L7.82277 13.2385C7.82277 13.2385 7.30434 13.5636 6.3323 13.271C5.36025 12.9783 4.22619 12.5881 4.22619 12.5881C4.22619 12.5881 3.44856 12.1004 4.77702 11.5801Z"></path></svg>
        </IconContainer>
      </FooterTextArea>
      <FooterTextArea>
        <IconContainer style={{ marginRight: 5 }}>
          <svg style={{ verticalAlign: 'middle' }} width="24" height="14" viewBox="0 0 16 10" fill="#000" xmlns="http://www.w3.org/2000/svg"><path d="M0.79878 0C0.727846 0 0.662962 0.0135385 0.597561 0.0300481L7.65854 6.25C7.83658 6.39625 8.17542 6.39625 8.34146 6.25L15.4036 0.0300481C15.337 0.0135288 15.2722 0 15.2012 0H0.79878ZM0.0304878 0.564904C0.0120176 0.629948 0 0.698404 0 0.769231V9.23077C0 9.3016 0.0120781 9.37006 0.0304878 9.4351L5.2622 5.16827L0.0304878 0.564904ZM15.9695 0.564904L10.7378 5.16827L15.9695 9.4351C15.9879 9.37006 16 9.3016 16 9.23077V0.769231C16 0.698404 15.988 0.629948 15.9695 0.564904ZM5.85366 5.69109L0.603658 9.96995C0.6673 9.98553 0.7299 10 0.79878 10H15.2012C15.2701 10 15.3327 9.98559 15.3963 9.96995L10.1463 5.69109L8.85976 6.8209C8.37182 7.25067 7.62818 7.25067 7.14036 6.8209L5.85366 5.69109Z"></path></svg>
        </IconContainer>
        <span style={{ lineHeight: '14px' }}>cs@mreverything.io</span>
      </FooterTextArea> */}
    </FooterContainer>
  )
}

export default Footer;