import { caver } from "../../caver";

export const GAS = 500000;

export function getContract(abi: any, address: string, walletType: string = 'kaikas'): any {
  return new window.caver.klay.Contract(abi, address);
}

export async function getNonce(walletInfo: WalletInfo): Promise<string> {
  const { type, address = '' } = walletInfo;
  if (type === 'kaikas') {
    return await caver.rpc.klay.getTransactionCount(address);
  }
  return '0x0';
}