import React, { Suspense, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import NftCard from "../../components/NftCard.tsx";
import AttributeFilterNav from "./AttributeFilterNav";
import ProjectTitle from "./ProjectTitle";
import { nftProjectItemsSelector } from "../../common/selector/nft";
import { nftProjectSelector } from "../../common/selector/project";
import Search from "antd/lib/input/Search";
import SortAndSearch, { SortAndSearchOption } from "./SortAndSearch";
import NftList from "./NftList";

const ProjectMarketContainer = styled.div``;
const TitleContainer = styled.div``;
const MarketBody = styled.div`
  padding: 0 10%;
  display: flex;
`;
const SortContainer = styled.div``;

const ProjectMarket = () => {
  const { projectId = "" } = useParams();
  const [searchOption, setSearchOption] = useState<SortAndSearchOption>();
  const [itemCount, setItemCount] = useState(0);

  const handleSearchedItemCount = (val: number) => {
    setItemCount(val);
  };

  const projectInfo = useRecoilValue(nftProjectSelector(projectId));

  return (
    <ProjectMarketContainer>
      <ProjectTitle projectInfo={projectInfo} />
      <SortContainer>
        <SortAndSearch
          itemCount={itemCount}
          searchOption={searchOption || {}}
          onSearch={(query: string) => {
            setSearchOption({
              ...searchOption,
              searchQuery: query,
            });
          }}
          onSort={(sort: SortAndSearchOption) => {
            setSearchOption({
              ...searchOption,
              order: sort.order,
              orderBy: sort.orderBy,
            });
          }}
        />
      </SortContainer>
      <MarketBody>
        {/* <AttributeFilterNav onChange={() => { }} projectId={projectId} /> */}
        <Suspense>
          <NftList
            projectInfo={projectInfo}
            sortAndSearchOption={searchOption || {}}
            setItemCount={handleSearchedItemCount}
          />
        </Suspense>
      </MarketBody>
    </ProjectMarketContainer>
  );
};

export default ProjectMarket;
