import { Col, Divider, Row } from "antd";
import {Link, useParams} from "react-router-dom";
import { useRecoilValue, useRecoilRefresher_UNSTABLE } from "recoil";
import styled from "styled-components";
import Page from "../../components/Page"
import { nftSelector, nftHistorySelector } from "../../common/selector/nft";
import History from "./History";
import NftDescription from "./NftDescription";
import Price from "./Price";
import ActivityList from "./ActivityList";

import Properties from "./Properties";

const NftPageContainer = styled.div`
  padding: 0 10%;
`;

const NftRightSection = styled.div`
  padding: 20px;
`;

const TitleContainer = styled.div`
  margin-top: 1rem;
  width: 100%;
  color: #0072FF;
  font-size: 32px;
  font-weight: 600;
`;

const OwnerContainer = styled.div`
  margin-top: 1rem;
  width: 100%;
`;

const OwnerTitle = styled.div`
  color: #999797;
  font-size: 18px;
`;

const OwnerAddress = styled.div`
  font-size: 15px;
  color: white;
`;

const NftPage = () => {
  const { projectId = "", tokenId = "" } = useParams();
  const nftItem = useRecoilValue(
    nftSelector({
      projectId,
      tokenId,
    })
  );

  return (
    <Page>
      <Row>
        <Col sm={{ span: 12 }} md={{ span: 12 }}>
          <NftDescription nftItem={nftItem} />
        </Col>
        <Col sm={{ span: 12 }} md={{ span: 12 }}>
          <NftRightSection>
            <TitleContainer>
                <Link to={`/nft-market/project/${nftItem.projectId}`}>{nftItem.metadata.name}</Link>
            </TitleContainer>
            <OwnerContainer>
              <OwnerTitle>Owned By</OwnerTitle>
              <OwnerAddress>{nftItem.owner}</OwnerAddress>
            </OwnerContainer>
            <Price nftItem={nftItem} />
            <History nftItem={nftItem} />
            <Properties nftItem={nftItem} />
          </NftRightSection>
        </Col>
      </Row>
      <Row style={{width: "100%"}}>
        <ActivityList nftItem={nftItem}/>
      </Row>
    </Page>
  );
};

export default NftPage;
