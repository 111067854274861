import Page from "../../components/Page"
import HomeBanner from "../../components/HomeBanner"
import UpcomingProjects from "./UpcomingProjects";
import NotableProjects from "./NotableProjects";
import Projects from "./Projects";
const Home = () => {
  return (
    <div
      style={{
        backgroundColor: "#131414ea",
        minHeight: "100vh"
      }}
    >
      <HomeBanner />
      <NotableProjects />
      <Projects />
    </div>
  )
};

export default Home;
