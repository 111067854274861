import styled from "styled-components";

const IconImg = styled.img`
  width: ${(props) => props.width || "32px"};
  height: ${(props) => props.height || "32px"};
`;

type KlayIconProps = {
  width?: string | undefined,
  height?: string | undefined,
}

const KlayIcon = ({width, height}: KlayIconProps) => {
  return <IconImg src={`/img/klay-icon.webp`} width={width} height={height}/>
}

export default KlayIcon;