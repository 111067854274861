import { Input } from "antd";
import React, { Suspense, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import { nftProjectsSelector } from "../../common/selector/project";
import ProjectCard from "../../components/ProjectCard";
import Projects from "./Projects";
import Page from "../../components/Page";


const { Search } = Input;

type TabProps = {
  clicked?: boolean
}

const SearchContainer = styled.div`
  width: 70%;
  display: flex;
  justify-content: end;
  align-items: center;
`
const CollectionTitle = styled.div`
  width: 30%;
  color: white;
  text-align: left;
  font-size: 32px;
  font-weight: 600;
`
const HeaderContainer = styled.div`
  padding-top: 50px;
  text-align: center;
  display: flex;
`
const TabContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
`;

const TabButton = styled.div<TabProps>`
  color: white;
  font-size: 16px;
  padding: 10px 20px;
  border-radius: 5px;
  ${props => props.clicked && `background: blue;`};
  cursor: pointer;
`;

const WrappedSearch = styled(Search)`
  width: 30%;
  margin-right: 0;
  .ant-input {
    background-color: transparent;
    border: 1px solid #6b6bd491;
    color: white;
  }

  .ant-btn {
    background-color: transparent;
    border: 1px solid #6b6bd491;
  }

  .ant-input-group-addon {
    background-color: transparent;
  }
`

const NftMarket = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const { category } = useParams();
  const navigate = useNavigate();

  return (
    <Page>
      <HeaderContainer>
        <CollectionTitle>Collections</CollectionTitle>
        <SearchContainer>
          <WrappedSearch placeholder="Search NFT Items"
            onSearch={(e) => { setSearchQuery(e) }} 
            enterButton
          />
        </SearchContainer>
      </HeaderContainer>
      <TabContainer>
        <TabButton 
          clicked={category == 'All'}
          onClick={(e) => {
            navigate("/nft-market/All", { replace: true });
          }}
        >All</TabButton>
        <TabButton 
          clicked={category == 'PFP'}
          onClick={(e) => {
            navigate("/nft-market/PFP", { replace: true });
          }}  
        >PFP</TabButton>
        <TabButton 
          clicked={category == 'Gaming'}
          onClick={(e) => {
            navigate("/nft-market/Gaming", { replace: true });
          }}  
        >Gaming</TabButton>
        <TabButton 
          clicked={category == 'Art'}
          onClick={(e) => {
            navigate("/nft-market/Art", { replace: true });
          }}  
        >Art</TabButton>
        <TabButton 
          clicked={category == 'Video'}
          onClick={(e) => {
            navigate("/nft-market/Video", { replace: true });
          }}  
        >Video</TabButton>
        <TabButton 
          clicked={category == 'Sport'}
          onClick={(e) => {
            navigate("/nft-market/Sport", { replace: true });
          }}  
        >Sport</TabButton>
        <TabButton 
          clicked={category == 'Utility'}
          onClick={(e) => {
            navigate("/nft-market/Utility", { replace: true });
          }}  
        >Utility</TabButton>
      </TabContainer>
      <Suspense fallback={null}>
        <Projects searchOptions={{
          category,
          title: searchQuery
        }} />
      </Suspense>
    </Page>
  );
};

export default NftMarket;
