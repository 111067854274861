import styled from 'styled-components';
import { BOXSHADOW } from "../../common/styles";
import { Row } from 'antd';

export const ProfileContainer = styled('div')`
  width: 100%;
`;


export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.div`
  font-size: 7em;
  color: #0072FF;

`;
export const Description = styled.div``;
export const BannerContainer = styled.div`
  width: 100%;
  min-height: 20vh;
  background-position: center;
`;

export const ProfileWrapper = styled.div`
  height: 75px;
`;

export const ProfileImageSection = styled.div`
  background-position: center;
  width: 150px;
  height: 150px;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  top: -75px;
  border-radius: 50%;
  border: solid 5px #fff;
  ${BOXSHADOW}
`;

export const Address = styled.div`
  color: white;
  width: 182px;
  height: 48px;
  margin-top: 10px;
  text-align: center;
  padding: 5px 5px;
  border-radius: 50px;
  ${BOXSHADOW}
  border: 1px solid #036FC9;
  cursor: pointer;
  font-size: 1.5rem;
`;

export const CollectionContainer = styled.div`
  padding: 0 10%;
  width: 100%;
  margin-top: 50px;
`

export const CollectionHeaderRow = styled(Row)`
  width: 100%;
  margin-top: 50px;
`;