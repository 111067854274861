import styled from 'styled-components';

export const HomeBannerContainer = styled('div')`
  width: 100%;
  height: auto;
  position: relative;
`

export const LeftBannerSection = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const BannerIntroTextArea = styled('div')`
  width: 100%;
  margin-top: 40px;
  object-fit: contain;
  font-family: AppleSDGothicNeo;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #727283;
`