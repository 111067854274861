import { useState } from "react";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import KlayIcon from "../../common/KlayIcon";
import { walletBalanceSelector } from "../../common/selector/blockchain";
import { walletState } from "../../common/states";
import { BASIC_BUTTON, GRAY, NEUMORPH } from "../../common/styles";
import {
  castTicker,
  convertDecimal,
  getTokenDecimal,
  isAddressEquals,
  isNftListed,
} from "../../common/utils/functools";
import CancelSellModal from "./CancelSellModal";
import SellModal from "./SellModal";
import NftBoxTitle from "../../common/NftBoxTitle";
import BuyModal from "./BuyModal";
import TetherIcon from "../../common/TetherIcon";

type PriceProps = {
  nftItem: NftItem;
};

const Container = styled.div`
  padding: 20px;
  margin-bottom: 32px;
  margin-top: 20px;
  border-radius: 20px;
  border: 1px #00B4FF solid;
  color: white;
`;

const PriceTitle = styled.div`
  font-size: 2.5rem;
  font-weight: bold;
`;

const NftPrice = styled.div`
  font-size: 4rem;
  font-weight: bold;
`;
const Balance = styled.div`
  margin-top: 4px;
  font-size: 1.5rem;
  color: ${GRAY};
`;

const Button = styled.button`
  margin-top: 2.5rem;
  margin-right: 2rem;
  ${BASIC_BUTTON}
`;

const BlueButton = styled(Button)`
  color: #ffffff;
  background: linear-gradient(90deg, #0860F8 0%, #008CFF 100%);
`;

const Price = ({ nftItem }: PriceProps) => {
  const balances = useRecoilValue(walletBalanceSelector);
  const walletInfo = useRecoilValue(walletState);
  const klayBalance = balances.find((balance) => balance.token === "KLAY");

  const isOwner = isAddressEquals(walletInfo.address || "", nftItem.owner);
  const isListed = isNftListed(nftItem);
  const renderBuyButton = !isOwner && isListed;
  const renderSellButton = isOwner && !isListed;
  const renderCancelSell = isOwner && isListed;

  const [sellModalVisible, setSellModalVisible] = useState(false);
  const [cancelSellModalVisible, setCancelSellModalVisible] = useState(false);
  const [buyModalVisible, setBuyModalVisible] = useState(false);

  return (
    <Container>
      <NftBoxTitle>Current Price</NftBoxTitle>
      <NftPrice>
        {nftItem.ticker === 'OUSDT' ? <TetherIcon /> : nftItem.price ? <KlayIcon /> : null} {convertDecimal(nftItem.price, getTokenDecimal(nftItem.ticker))} {castTicker(nftItem.ticker)}
      </NftPrice>
      <Balance>
        Your Current KLAY Balance: {convertDecimal(klayBalance?.balance)}
      </Balance>
      {renderBuyButton ? (
        <BlueButton
          onClick={(e) => {
            setBuyModalVisible(!buyModalVisible);
          }}
        >
          Buy Now
        </BlueButton>
      ) : null}
      {renderSellButton ? (
        <BlueButton
          onClick={(e) => {
            setSellModalVisible(!sellModalVisible);
          }}
        >
          Sell
        </BlueButton>
      ) : null}
      {renderCancelSell ? (
        <BlueButton
          onClick={(e) => {
            setCancelSellModalVisible(!cancelSellModalVisible);
          }}
        >
          Cancel Sell
        </BlueButton>
      ) : null}
      <SellModal
        visible={sellModalVisible}
        nftItem={nftItem}
        toggleVisible={() => {
          setSellModalVisible(!sellModalVisible);
        }}
      />
      <CancelSellModal
        visible={cancelSellModalVisible}
        nftItem={nftItem}
        toggleVisible={() => {
          setCancelSellModalVisible(!cancelSellModalVisible);
        }}
      />
      <BuyModal
        visible={buyModalVisible}
        nftItem={nftItem}
        toggleVisible={() => {
          setBuyModalVisible(!buyModalVisible)
        }}
      />
    </Container>
  );
};

export default Price;
