import { Col, InputNumber, Modal as AntModal, Row, Select } from "antd";
import { useState } from "react";
import {
  useRecoilRefresher_UNSTABLE,
  useRecoilState,
  useRecoilValue,
} from "recoil";
import styled from "styled-components";
import { pendingState, walletState } from "../../common/states";
import { sleep } from "../../common/utils/functools";
import { putOnSale } from "../../common/utils/market";
import { nftSelector, nftHistorySelector } from "../../common/selector/nft";
import { useParams } from "react-router-dom";

const { Option } = Select;

type SellModalProps = {
  nftItem: NftItem;
  visible: boolean;
  toggleVisible: () => void;
};

const Modal = styled(AntModal)``;

const SellModal = ({ nftItem, visible, toggleVisible }: SellModalProps) => {
  const { projectId = "", tokenId = "" } = useParams();
  const [price, setPrice] = useState(0);
  const [token, setToken] = useState("KLAY");
  const walletInfo = useRecoilValue(walletState);
  const [pending, setPending] = useRecoilState(pendingState);
  const refresher = useRecoilRefresher_UNSTABLE(
    nftSelector({
      projectId,
      tokenId,
    })
  );

  const refreshNftHistory = useRecoilRefresher_UNSTABLE(
    nftHistorySelector({
      projectId,
      tokenId,
    })
  );

  //TODO: usdt 처리
  return (
    <Modal
      visible={visible}
      title={`Sell ${nftItem.metadata.name}`}
      onCancel={() => toggleVisible()}
      onOk={async () => {
        await putOnSale(nftItem, token, price.toString(), walletInfo);
        setPending(true);
        await sleep(3000);
        setPending(false);
        toggleVisible();
        refresher();
        refreshNftHistory();
      }}
    >
      <Row>
        <Col span={24}>
          <InputNumber
            addonAfter={
              <Select
                value={token}
                style={{ width: 100 }}
                onSelect={(e: string) => {
                  setToken(e);
                }}
              >
                <Option value="KLAY">KLAY</Option>
                {/* <Option value="OUSDT">oUSDT</Option> */}
              </Select>
            }
            stringMode={true}
            defaultValue={price}
            onChange={(e) => {
              setPrice(e ?? 0);
            }}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default SellModal;
