import React, { useState } from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import { useRecoilValue } from "recoil";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useNavigate } from 'react-router-dom';
import { upcomingProjectsSelector } from "../../common/selector/project";

const NotableProjects = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const navigateTo = useNavigate();
  const upcomingProjects = useRecoilValue(upcomingProjectsSelector);


  const bannerSettings = {
    dots: true,
    autoplay: true,
    autoplaySpeed: 3000,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (oldIndex: number, newIndex: number) => {
      setCurrentSlide(newIndex);
    },
  };

  return (
    <NotableProejctContainer>
      <Title>Notable Collections</Title>
      <Slider {...bannerSettings}>
        {upcomingProjects.filter(it => it.projectId == 'fantasylove' || it.projectId == 'princeworld').map(project => 
        <BannerContainer backgroundUrl={project.imgBackgroundUrl}  onClick={() => {navigateTo(`/project/${project.projectId}`);}}>
          <BannerTitleBox>
            <p>{project.title}</p>
          </BannerTitleBox>
        </BannerContainer>
        )
        }
      </Slider>
    </NotableProejctContainer>
  );
};

export default NotableProjects;

const NotableProejctContainer = styled.div`
  padding: 0 10%;

  .slick-dots li.slick-active button:before {
      opacity: .85;
      color: #4c98ea;
  }

  .slick-dots li button:before {
    opacity: .25;
    color: #4c98ea;
  }
`;

const BannerContainer = styled.div<{
  backgroundUrl?: string;
  backgroundColor?: string;
}>`
  position: relative;
  display: flex !important;
  padding: 56px;
  width: 100%;
  height: 470px;
  background-size: cover;
  ${props =>
    props.backgroundUrl && `background-image: url(${props.backgroundUrl});`};
  ${props => props.backgroundColor && `background: ${props.backgroundColor};`};
  color: #ffffff;
  border-radius: 18px;
  margin-bottom: 10px;

`;

const BannerTitleBox = styled.div`
  z-index: 1;
  display: flex;
  justify-content: start;
  flex-direction: column;
  p {
    text-shadow: 2px 2px 4px rgba(87, 87, 87, 0.5);
    color: white;
    font-size: 36px;
    font-family: 'Baloo 2';
    margin-bottom: 0.5rem;
    font-weight: bold;
  }
`;

const Title = styled.div`
  margin-top: 50px;
  text-align: center;
  color: white;
  font-family: AppleSDGothicNeo;
  font-size: 48px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  margin-bottom: 24px;
`;


