import axios from "axios";
import { selector, selectorFamily } from "recoil";
import { getLaunchpadProject, getLaunchpadProjects } from "../requesters/launchpad";

type ProjectCategory = string;

export type ProjectSearchOptions = {
  category?: string,
  title?: string,
}

export const nftLaunchpadsSelector = selectorFamily<NftLaunchpadItem[], ProjectSearchOptions>({
  key: "nftLaunchpadsSelector",
  get: (options: ProjectSearchOptions) => async (): Promise<NftLaunchpadItem[]> => {
    return await getLaunchpadProjects(options);
  },
});

export const nftLaunchpadSelector = selectorFamily<NftLaunchpadItem, ProjectId>({
  key: "nftLaunchpadSelector",
  get: (projectId: ProjectId) => async (): Promise<NftLaunchpadItem> => {
    return await getLaunchpadProject(projectId);
  }
})

export type ProjectId = string;
