import React from "react";
import { useCopyToClipboard } from "usehooks-ts";
import { notification } from 'antd';
import styled from "styled-components";

interface CopyLinkButtonProps {
  copyTarget: string;
  width?: string;
  imgSrc?: string;
}

const CopyLinkButtonImage = styled.img`
  width: 100%;
  border-radius: 10px;
  cursor: pointer;
  
`;

const openNotification = (copyTarget: string) => {
  notification.open({
    message: 'Link copied',
    description: copyTarget
  });
};

export const CopyLinkButton = ({copyTarget, width, imgSrc} : CopyLinkButtonProps) => {
  const [value, copy] = useCopyToClipboard();

  return (
    <div style={{minWidth: width || "20px", width: width || "20px" }}>
      <CopyLinkButtonImage
        src={imgSrc || `/img/copy-link-btn.png`}
        onClick={(e) => {
          copy(copyTarget);
          openNotification(copyTarget);
        }}
      />
    </div>
  );
};

