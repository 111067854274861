import { selectorFamily } from "recoil";
import { SortAndSearchOption } from "../../pages/ProjectMarket/SortAndSearch";
import {
  getNft,
  getNftHistory,
  getNftsByQuery,
  getProjectNfts,
  getWalletNfts,
} from "../requesters/nft";
import { ProjectId } from "./project";

export type NftSelectorParam = {
  projectId: string;
  tokenId: string;
};

export type ProjectNftQuery = {
  projectId: ProjectId;
  sortAndSearchOption?: SortAndSearchOption;
};

export type UserCollectionNftQuery = {
  walletAddress: WalletAddress;
  sortAndSearchOption?: SortAndSearchOption;
};

export type NftItems = {
  nftItems: NftItem[];
  total: number;
};

export const nftItemsQuerySelector = selectorFamily<NftItems, ProjectNftQuery>({
  key: "nftItemsQuerySelector",
  get: (query: ProjectNftQuery) => async (): Promise<NftItems> => {
    return getNftsByQuery(query);
  },
});

export const nftProjectItemsSelector = selectorFamily<NftItem[], ProjectId>({
  key: "nftProjectItemsSelector",
  get: (projectId: ProjectId) => async (): Promise<NftItem[]> => {
    return await getProjectNfts(projectId);
  },
});

export const nftSelector = selectorFamily<NftItem, NftSelectorParam>({
  key: "nftSelector",
  get:
    ({ projectId, tokenId }: NftSelectorParam) =>
    async (): Promise<NftItem> => {
      return await getNft(projectId, tokenId);
    },
});

export const nftHistorySelector = selectorFamily<NftLog[], NftSelectorParam>({
  key: "nftHistorySelector",
  get:
    ({ projectId, tokenId }: NftSelectorParam) =>
    async (): Promise<NftLog[]> => {
      return await getNftHistory(projectId, tokenId);
    },
});

export type WalletAddress = string;

export const walletNftItemsSelector = selectorFamily<
  NftItem[],
  UserCollectionNftQuery
>({
  key: "walletNftItemsSelector",
  get: (query: UserCollectionNftQuery) => async (): Promise<NftItem[]> => {
    return await getWalletNfts(query);
  },
});
