import styled from "styled-components";

const IconImg = styled.img`
  width: ${(props) => props.width || "32px"};
  height: ${(props) => props.height || "32px"};
`;

type TetherIconProps = {
  width?: string | undefined,
  height?: string | undefined,
}

const TetherIcon = ({ width, height }: TetherIconProps) => {
  return <IconImg src={`/img/tether-icon.svg`} width={width} height={height} />
}

export default TetherIcon;