import { LoadingOutlined } from "@ant-design/icons";
import styled from "styled-components"
import { GLASS } from "../common/styles"
import { Spin } from 'antd';
import { useRecoilValue } from "recoil";
import { pendingState } from "../common/states";

const PendingContainer = styled.div`
  width: 100%;
  height: 100%;
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  ${GLASS}
  background: rgba(80,80,80,0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`

const antIcon = <LoadingOutlined style={{ fontSize: '10rem' }} spin />;

const Pending = () => {
  const pending = useRecoilValue(pendingState);
  return pending ? <PendingContainer>
    <Spin indicator={antIcon} />
  </PendingContainer> : null;
}

export default Pending