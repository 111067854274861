import { klaytn } from "../../caver";
import { getProject } from "../requesters/project";
import { kip17Abi, kip7Abi, nftMarketAbi, mintAbi } from "./abi";
import { GAS, getContract, getNonce } from "./blockchain";
import {
  getTokenAddress,
  getTokenDecimal,
  revConvertDecimal,
  sleep,
} from "./functools";
import Web3EthAbi from "web3-eth-abi";

export const nftMarketAddress = "0xF63bF3cd1e0DAd83D97Ffa531C6F14f92a119579";
export const mintContractAddress = "0x684467C11E3e66Ee8d13652693275D6701ccA0Ee";

type Contract = any;

export async function getNftMarketContract(
  walletType: string = "kaikas"
): Promise<Contract> {
  return getContract(nftMarketAbi, nftMarketAddress, walletType);
}

export async function getKip17Contract(
  address: string,
  walletType: string = "kaikas"
): Promise<Contract> {
  return getContract(kip17Abi, address, walletType);
}

export async function getMintContract(
  walletType: string = "kaikas",
  mintContractAddress: string
): Promise<Contract> {
  return getContract(mintAbi, mintContractAddress, walletType);
}

export async function buyNft(
  nftItem: NftItem,
  walletInfo: WalletInfo
): Promise<boolean> {
  const marketContract = await getNftMarketContract(walletInfo.type);
  const project = await getProject(nftItem.projectId);
  if (nftItem.ticker === "KLAY") {
    await marketContract.methods
      .buyInKLAY(project.address, nftItem.tokenId)
      .send({
        type: "SMART_CONTRACT_EXECUTION",
        from: walletInfo.address,
        value: nftItem.price,
        gas: GAS,
      });
    return true;
  } else {
    const timeout = 30000;
    const nonce = await getNonce(walletInfo);
    const tokenContract = getContract(kip7Abi, getTokenAddress(nftItem.ticker));
    const data = Web3EthAbi.encodeParameters(
      ["address", "uint256"],
      [project.address, nftItem.tokenId]
    );
    tokenContract.methods
      .safeTransfer(marketContract._address, nftItem.price, data)
      .send({
        type: "SMART_CONTRACT_EXECUTION",
        from: walletInfo.address,
        gas: GAS,
      });

    const startTime = new Date().getTime();

    while (
      (await getNonce(walletInfo)) === nonce &&
      new Date().getTime() - startTime < timeout
    ) {
      await sleep(500);
    }
    if (new Date().getTime() - startTime > timeout) {
      return false;
    }
    return true;
  }
}

export async function putOnSale(
  nftItem: NftItem,
  token: string,
  price: string,
  walletInfo: WalletInfo
) {
  const marketContract = await getNftMarketContract(walletInfo.type);
  const project = await getProject(nftItem.projectId);
  const tokenContract = await getKip17Contract(
    project.address,
    walletInfo.type
  );
  const isApproved = await tokenContract.methods
    .isApprovedForAll(walletInfo.address, marketContract._address)
    .call();
  if (!isApproved) {
    await tokenContract.methods
      .setApprovalForAll(marketContract._address, true)
      .send({
        from: walletInfo.address,
        gas: GAS,
      });
  }
  await marketContract.methods
    .putOnSale(
      project.address,
      nftItem.tokenId,
      getTokenAddress(token),
      revConvertDecimal(price, getTokenDecimal(token))
    )
    .send({
      from: walletInfo.address,
      gas: GAS,
    });
}

export async function cancelSale(nftItem: NftItem, walletInfo: WalletInfo) {
  const marketContract = await getNftMarketContract(walletInfo.type);
  const project = await getProject(nftItem.projectId);
  await marketContract.methods
    .cancelSale(project.address, nftItem.tokenId)
    .send({
      from: walletInfo.address,
      gas: GAS,
    });
}

export async function mintNft(walletInfo: WalletInfo, address: string) {
  const mintContract = await getMintContract(walletInfo.type, address);
  await mintContract.methods.mint().send({
    from: walletInfo.address,
    gas: GAS,
  });
}

export async function getNftBalance(walletInfo: WalletInfo, address: string) {
  const mintContract = await getMintContract(walletInfo.type, address);
  const balance = parseInt(await mintContract.methods.totalSupply().call());
  return balance;
}
