import React from 'react';

import { Styles } from '../styles/global';
import Header from '../components/Header';
import Footer from '../components/Footer';
import RouterConfig from './RouterConfig';

const Router = () => {
  return (
    <React.Suspense fallback={null}>
      <Styles />
      <Header />
      <RouterConfig />
      <Footer />
    </React.Suspense>
  );
};

export default Router;