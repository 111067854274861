import axios from "axios";
import { selector, selectorFamily } from "recoil";
import { getProject, getProjectAttributes, getProjects, getUpcomingProjects } from "../requesters/project";

type ProjectCategory = string;

export type ProjectSearchOptions = {
  category?: string,
  title?: string,
}

export const nftProjectsSelector = selectorFamily<NftProject[], ProjectSearchOptions>({
  key: "nftProjectsSelector",
  get: (options: ProjectSearchOptions) => async (): Promise<NftProject[]> => {
    return await getProjects(options);
  },
});

export const nftProjectSelector = selectorFamily<NftProject, ProjectId>({
  key: "nftProjectSelector",
  get: (projectId: ProjectId) => async (): Promise<NftProject> => {
    return await getProject(projectId);
  }
})

export type ProjectId = string;

export const projectAttributesSelector = selectorFamily<ProjectAttributes, ProjectId>({
  key: 'projectAttributesSelector',
  get: (projectId: ProjectId) => async (): Promise<ProjectAttributes> => {
    return await getProjectAttributes(projectId);
  }
})

export const upcomingProjectsSelector = selector({
  key: 'upcomingProjectsSelector',
  get: async (): Promise<NftProject[]> => {
    return await getUpcomingProjects();
  }
})