import { CopyOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { useCopyToClipboard } from "usehooks-ts";
import { BOXSHADOW } from "../../common/styles";
import { ellipseAddress } from "../../common/utils/functools";
import { ProjectDashboard } from "./ProjectDashboard";

type ProjectTitleProps = {
  projectInfo: NftProject;
};

const TitleContainer = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Title = styled.div`
  color: #0072FF;
  margin-top: 24px;
  font-size: 7em;
  font-weight: bold;
`;
const Description = styled.div`
  padding: 0 10%;
  margin-top: 24px;
  color: #A0A0A0;
  font-family: AppleSDGothicNeo;
  font-size: 1.5rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #45455e;
`;
const BannerContainer = styled.div`
  width: 100%;
  min-height: 250px;
  background-position: center;
`;

const ProjectProfileWrapper = styled.div`
  height: 75px;
`;

const ProjectProfile = styled.div`
  background-position: center;
  width: 150px;
  height: 150px;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  top: -75px;
  background-color: #fff;
  border-radius: 50%;
  border: solid 10px #fff;
  ${BOXSHADOW}
`;

const Address = styled.div`
  font-size: 1.5rem;
  width: 20rem;
  text-align: center;
  margin-top: 14px;
  padding: 10px 15px;
  border-radius: 50px;
  border: 1px solid #036FC9;
  ${BOXSHADOW}
  cursor: pointer;
`;

const ProjectTitle = ({ projectInfo }: ProjectTitleProps) => {
  // const projectInfo = useRecoilValue(nftProjectSelector(projectId));
  const [value, copy] = useCopyToClipboard();
  return (
    <TitleContainer>
      <BannerContainer
        style={{
          // backgroundImage: `url(${projectInfo.imgBackgroundUrl})`,
        }}
      ></BannerContainer>
      <ProjectProfileWrapper>
        <ProjectProfile
          style={{
            backgroundImage: `url(${projectInfo.imgProfileUrl})`,
          }}
        ></ProjectProfile>
      </ProjectProfileWrapper>
      <Title>{projectInfo.title}</Title>
      <Address
        onClick={(e) => {
          copy(projectInfo.address);
        }}
      >
        {ellipseAddress(projectInfo.address)}{" "}
        <CopyOutlined />
      </Address>
      <Description>{projectInfo.description}</Description>
      <ProjectDashboard  
        totalSupplyCount={projectInfo.totalSupplyCount}
        floorPrice={projectInfo.floorPrice}
        tradedVolume={projectInfo.tradedVolume}
        createdDate={projectInfo.createdDate}
      />
    </TitleContainer>
  );
};

export default ProjectTitle;
