import { ConfigProvider, Empty, Table as AntTable } from "antd";
import { ColumnsType } from "antd/lib/table";
import styled from "styled-components";
import { BACKGROUND, GRAY } from "../../common/styles";

type TableProps = {
  colums: ColumnsType<any>;
  dataSource: any[];
}

const StyledTable = styled(AntTable)`
  & .ant-table-container {
    background-color: ${BACKGROUND};
  }
`

const Table = ({ colums, dataSource }: TableProps) => {
  return <ConfigProvider renderEmpty={() => <Empty imageStyle={{ display: 'none' }} description={'No Price History'} />}>
    <StyledTable
      pagination={{ pageSize: 5 }}
      columns={colums}
      dataSource={dataSource}
    />
  </ConfigProvider>
}

export default Table;