import axios from "axios";
import { CONFIG } from "../../config";
import { ProjectSearchOptions } from "../selector/project";
import { requester } from "../utils/requester";

export async function getProjects(options: ProjectSearchOptions): Promise<NftProject[]> {
  const res = await requester.get("/project", {
    params: {
      ...options
    }
  });
  return res.data as NftProject[];
}

export async function getUpcomingProjects(): Promise<NftProject[]> {
  const res = await requester.get("/project/upcoming");
  return res.data as NftProject[];
}

export async function getProject(projectId: string): Promise<NftProject> {
  const res = await requester.get(`/project/${projectId}`)
  return res.data as NftProject;
}

export async function getProjectAttributes(projectId: string): Promise<ProjectAttributes> {
  const res = await requester.get(`/nft/${projectId}/attributes`);
  return res.data as ProjectAttributes;
}