import { useRecoilState, useSetRecoilState } from "recoil";
import React, { useState, useEffect } from "react";
import { walletState } from "../../common/states";
import {
  ProfileContainer,
  TitleContainer,
  BannerContainer,
  ProfileWrapper,
  ProfileImageSection,
  Title,
  Address,
} from "./styles";
import { Input } from "antd";
import UserCollection from "./UserCollection";
import { ellipseAddress } from "../../common/utils/functools";
import { CopyOutlined } from "@ant-design/icons";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import { useCopyToClipboard } from "usehooks-ts";
import { Link, useNavigate } from "react-router-dom";
import { walletNftItemsSelector } from "../../common/selector/nft";
import HomeBanner from "../../components/HomeBanner";

const Profile = () => {
  const [walletStatus, setWalletStatus] = useRecoilState(walletState);
  const [value, copy] = useCopyToClipboard();
  const navigate = useNavigate();

  useEffect(() => {
    if (walletStatus.address === undefined) {
      navigate("/");
    }

  }, [walletStatus.address])

  return (
    <ProfileContainer>
      <TitleContainer>
        <BannerContainer
            style={{
              background: `url("/img/visual_bg.webp") no-repeat center center / cover`,
            }}
        />
        <ProfileWrapper>
          <ProfileImageSection
            style={{
              backgroundImage: "url('/img/profile-default-blue.png')",
            }}
          ></ProfileImageSection>
        </ProfileWrapper>
        <Address
          onClick={(e) => {
            copy(walletStatus.address ? walletStatus.address : '');
          }}
        >
          <div style={{ marginTop: "5px" }}>
            <span style={{ marginRight: "1rem" }}>{ellipseAddress(walletStatus.address ? walletStatus.address : '')}</span>
            <CopyOutlined />
          </div>
        </Address>
      </TitleContainer>
      <UserCollection />
    </ProfileContainer>
  );
};

export default Profile;
