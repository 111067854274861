import styled from 'styled-components';


export const FooterContainer = styled('div')`
  margin-top: 50px;
  width: 100%;
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const FooterTextArea = styled('div')`
  font-family: AppleSDGothicNeo;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  color: #b5b5be;
`;