import { Card } from "antd";
import styled from "styled-components";
import KlayIcon from "../../common/KlayIcon";
import TetherIcon from "../../common/TetherIcon";
import {
  castTicker,
  convertDecimal,
  getTokenDecimal,
} from "../../common/utils/functools";
import { checkIsVideo } from "../../common/utils/nft";

const NftCardContainer = styled.div`
  width: 28rem;
  margin: 15px;

  .ant-card-body {
    padding-left: 6px;
  }
`;

const NftCardDesctiptionContainer = styled.div`
  height: 6rem;
  padding: 0.5em;
`;

const NftCardImage = styled.img`
  border-radius: 20px !important;
  height: 28rem;
`;

const NftCardVideo = styled.video`
  border-radius: 20px !important;
  height: 28rem;
`;

const NftDescTitle = styled.div`
  color: #FFFFFF;
  font-size: 2rem;
  font-weight: bold;
`;

const NftDescName = styled.div`
  color: #FFFFFF;
  margin-top: 0.5rem;
  font-size: 1.5rem;
  font-weight: 600;
  color: #b5b5be;
`;

const NftPriceDiv = styled.div`
  display: flex;
  font-size: 1.5rem;
  font-weight: 500;
  color: #FFFFFF;
`;

type NftCardProps = {
  nftItem: NftItem;
  projectName?: string;
};

const NftCard = ({ nftItem, projectName }: NftCardProps) => {
  const { metadata } = nftItem;
  const isVideo = checkIsVideo(nftItem.projectId, nftItem.tokenId);
  return (
    <NftCardContainer>
      <Card
        style={{ borderRadius: "20px", padding: "0.5rem", backgroundColor: "black", border: "1px solid black" }}
        hoverable
        cover={
          isVideo ? (
            <NftCardVideo>
              <source src={nftItem.imgUrl} />
            </NftCardVideo>
          ) : (
            <NftCardImage src={nftItem.imgUrl} loading={"lazy"} />
          )
        }
      >
        <NftCardDesctiptionContainer>
          <NftDescTitle>#{projectName}</NftDescTitle>
          <NftDescName>#{nftItem.tokenId}</NftDescName>
          <NftPriceDiv>
            <span style={{ marginLeft: "0.5rem" }}>
              {convertDecimal(nftItem.price, getTokenDecimal(nftItem.ticker))}{" "}
              {castTicker(nftItem.ticker)}
            </span>
          </NftPriceDiv>
        </NftCardDesctiptionContainer>
        {/* <Card.Meta
          style={{ width: "100%" }}
          title={metadata.name}
          description={
            <NftCardDesctiptionContainer>
              <div>{nftItem.metadata?.name}</div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  alignContent: "center",
                }}
              >
                <KlayIcon width="1.5rem" height="1.5rem" />
                <span>
                  {convertDecimal(nftItem.price)} {nftItem.ticker}
                </span>
              </div>
            </NftCardDesctiptionContainer>
          }
        /> */}
      </Card>
    </NftCardContainer>
  );
};

export default NftCard;
