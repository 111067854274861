import { Col, Row, Button, Progress } from "antd";
import {Link, useParams} from "react-router-dom";
import { useRecoilValue } from "recoil";
import { pendingState, walletState } from "../../common/states";
import styled from "styled-components";
import { nftLaunchpadSelector } from "../../common/selector/launchpad";
import {
  mintNft,
  getMintContract,
  getNftBalance,
} from "../../common/utils/market";
import { nftSelector, nftHistorySelector } from "../../common/selector/nft";
import { useCopyToClipboard } from "usehooks-ts";
import { CopyLinkButton } from "../../components/CopyLinkButton";
import { MINT_PROGRESS } from "../../common/styles";
import { useEffect, useState } from "react";
import Page from "../../components/Page";
import CompoundedSpace from "antd/lib/space";

const MintPageContainer = styled.div`
  padding: 0 10%;
  padding-top: 10%;
`;

const MintRightSection = styled.div`
  width: 100%;
  padding: 4rem;
`;

const MintImageBox = styled.div`
  width: 100%;
  padding: 2rem;
`;

const MintImage = styled.img`
  width: 100%;
  object-fit: contain;
  border-radius: 20px;
`;

const MintText = styled.p`
  color: white;
  font-size: 5rem;
  margin:0;
  font-family: Poppins;
  font-weight: 600;
  line-height: 28.50px;
  word-wrap: break-word;
`;

const MintProjectTitle = styled.div`
  font-size: 4rem;
  color: #0072FF;
  font-family: Poppins;
  font-weight: 600;
  word-wrap: break-word;
  display: flex;
  align-items: center;
  vertical-align: middle;

`;

const VerifiedIcon = styled.img.attrs({
    src: "/img/ico_verified.svg",
})`
    width: 3rem;
    height: 3rem;
    margin-left: 10px;

  vertical-align: middle;

`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-start;
  //justify-content: space-between;
    align-items: center;
  width: 20%;
    margin-top: 10px;
`;

const IconButton = styled.img`
    width: 30px;
    height: 30px;
  margin-left: 20px;
`

const MintProgressSection = styled.div`
width: 100%;
  height: 100%;
  border-radius: 20px;
  border: 1px #00B4FF solid;
  color: white;
  margin-top: 40px;
  padding: 20px;
`;

const MintInfoSection = styled.div`
    display: flex;
  
    justify-content: left;
  flex-direction: column;

  padding-left: 4rem;
    margin-top: 30px;
`;
const MintInfoSectionTitle = styled.div`
  color: #B6CEEA;
    font-size: 1.5rem;
    font-weight: 600;
`;

const MintInfoSectionContent = styled.div`
  color: white;
    font-size: 1.5rem;
    font-weight: 600;
`;

const MintButtonSection = styled.div`
  text-align: center;
  margin-top: 40px;
`;

export const MintInfoBox = styled.div`
  padding: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  border: 1px solid #000;
  border-radius: 10px;
  width: 10rem;
  height: 6rem;
  border: solid 1px #0B5E90;
  background-color: #121429;
`;

export const MintInfoTitle = styled.div`
  font-size: 1.3rem;
  font-weight: 600;
  color: #4678B5;
`;

export const MintButton = styled("button")`
  display: inline-block;
  width: 100%;
  height: 6rem;
  border: 0;
  font-size: 2rem;
  border-radius: 1rem;
  cursor: pointer;
  color: white;
  background: linear-gradient(90deg, #0860F8 0%, #008CFF 100%);

  @media only screen and (max-width: 890px) {
    width: 200px;
  }
`;

export const SoldOutButton = styled("button")`
  display: inline-block;
  width: 100%;
  border: 0;
  font-size: 2rem;
  border-radius: 1rem;
  cursor: none;
  color: #9c9a9a;
  background: linear-gradient(90deg, #0860F8 0%, #008CFF 100%);

`;

const DisabledLink = styled(Link)`
    pointer-events: none; /* This makes it unclickable */
    color: #cccccc; /* Gray out the text */
    cursor: default;
`

const MintPage = () => {
  const walletInfo = useRecoilValue(walletState);
  const [totalSupply, setTotalSupply] = useState(0);
  const { projectId = "" } = useParams();

  const onClickMintButton = () => {
    mintNft(walletInfo, launchpadItem.mintAddress);
  };

  const getTotalSupply = async () => {
    const totalSupplyMinted = await getNftBalance(walletInfo, launchpadItem.mintAddress);
    setTotalSupply(totalSupplyMinted);
  };

  const getMintPercentage = () => {
    const minted = totalSupply * 100 / launchpadItem.totalSupply;
    return minted.toFixed(2);
  };

  const launchpadItem = useRecoilValue(
    nftLaunchpadSelector(projectId)
  );

  useEffect(() => {
    getTotalSupply();
  }, [walletInfo]);

  return (
    <Page>
      <Row gutter={10}>
        <Col sm={{ span: 2 }} md={{ span: 2 }}/>
        <Col sm={{ span: 10 }} md={{ span: 10 }}>
          <MintImageBox style={{}}>
            <MintImage src={launchpadItem.imgProfileUrl} />
          </MintImageBox>
        </Col>
        <Col sm={{ span: 10 }} md={{ span: 10 }} style={{ width: "100%" }}>
          <MintRightSection>
            <MintText>Mint</MintText>
            <MintProjectTitle>{launchpadItem.title}<VerifiedIcon/></MintProjectTitle>
            <ButtonContainer>
              <CopyLinkButton
                  imgSrc={"/img/ico_copy.png"}
                  copyTarget={`${window.location.origin}`}
                  width="30px"
              />
              <DisabledLink to={""} target="_blank"><IconButton src="/img/ico_twitter.svg"/></DisabledLink>
              <DisabledLink to={""} target="_blank"><IconButton src="/img/ico_discord.svg"/></DisabledLink>
              <DisabledLink to={""} target="_blank"><IconButton src="/img/ico_telegram.svg"/></DisabledLink>
              <DisabledLink to={""} target="_blank"><IconButton src="/img/ico_insta.svg"/></DisabledLink>
            </ButtonContainer>

            <MintProgressSection>
              <Row style={{marginBottom: "1rem"}}>
                <Col span={8}>
                  <span style={{ fontSize: "1.5rem", fontWeight: "600" }}>
                    Price
                  </span>
                </Col>
                <Col span={16} style={{ textAlign: "end" }}>
                  <span style={{ fontSize: "1.5rem", fontWeight: "600" }}>
                    {launchpadItem.price}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  <span style={{ fontSize: "1.5rem", fontWeight: "600" }}>
                    Total Mint
                  </span>
                </Col>
                <Col span={16} style={{ textAlign: "end" }}>
                  <span style={{ fontSize: "1.5rem", fontWeight: "600" }}>
                    {totalSupply} / {launchpadItem.totalSupply}
                  </span>
                </Col>
              </Row>
              <Progress
                strokeColor={{
                  "0%": "#0860F8",
                  "100": "#008CFF",
                }}
                size={"default"}
                showInfo={false}
                percent={parseFloat(getMintPercentage())}
              />
            </MintProgressSection>
            <MintButtonSection>
              {totalSupply < launchpadItem.totalSupply ? (
                <MintButton onClick={onClickMintButton}>MINT</MintButton>
              ) : (
                <SoldOutButton>SOLD OUT</SoldOutButton>
              )}
            </MintButtonSection>
          </MintRightSection>
          <MintInfoSection>
            <MintInfoSectionTitle>Collection</MintInfoSectionTitle>
            <MintInfoSectionContent>{launchpadItem.subTitle}</MintInfoSectionContent>
          </MintInfoSection>
          <MintInfoSection>
            <MintInfoSectionTitle>Owned By</MintInfoSectionTitle>
            <MintInfoSectionContent>{launchpadItem.ownerAddress}</MintInfoSectionContent>
          </MintInfoSection>
          <MintInfoSection>
            <MintInfoSectionTitle>Description</MintInfoSectionTitle>
            <MintInfoSectionContent>{launchpadItem.description}</MintInfoSectionContent>
          </MintInfoSection>
        </Col>
      </Row>
    </Page>
  );
};

export default MintPage;
