import { Col, Row, Select } from "antd";
import { useState, useEffect } from "react";
import { useRecoilValue, useRecoilRefresher_UNSTABLE } from "recoil";
import Search from "antd/lib/input/Search";
import { walletState } from "../../common/states";
import { CollectionHeaderRow, CollectionContainer } from "./styles";
import { Link } from "react-router-dom";
import { SortAndSearchOption } from "../ProjectMarket/SortAndSearch";
import UserCollectionSortAndSearch from "./UserCollectionSortAndSearch";
import UserCollectionNftList from "./UserCollectionNftList";

const UserCollection = () => {
  const { Option } = Select;
  const wallet = useRecoilValue(walletState);
  const [searchOption, setSearchOption] = useState<SortAndSearchOption>();
  const [itemCount, setItemCount] = useState(0);

  const handleSearchedItemCount = (val: number) => {
    setItemCount(val);
  };

  return (
    <CollectionContainer>
      <UserCollectionSortAndSearch
        itemCount={itemCount}
        searchOption={searchOption || {}}
        onSearch={(query: string) => {
          setSearchOption({
            ...searchOption,
            searchQuery: query,
          });
        }}
        onSort={(sort: SortAndSearchOption) => {
          setSearchOption({
            ...searchOption,
            order: sort.order,
            orderBy: sort.orderBy,
          });
        }}
      />
      <UserCollectionNftList
        sortAndSearchOption={searchOption || {}}
        setItemCount={handleSearchedItemCount}
      />
    </CollectionContainer>
  );
};

export default UserCollection;
