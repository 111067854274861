import { Card } from "antd";
import { Link } from "react-router-dom";
import styled from "styled-components";
import KlayIcon from "../../common/KlayIcon";
import { BOXSHADOW, GLASS } from "../../common/styles";
import TetherIcon from "../../common/TetherIcon";

type LaunchpadProjectCardProps = {
  launchpadItem: NftLaunchpadItem;
};

const CARD_WIDTH = 28;

type CardContainerProps = {
  backgroundUrl: string;
}

const CardContainer = styled.div<CardContainerProps>`
  border-radius: 20px;
  margin: 10px 30px;
  background-image: url(${(props) => props.backgroundUrl || ''});
  background-size: cover;
  width: ${CARD_WIDTH}rem;
  height: ${CARD_WIDTH}rem;
`;

const ProjectTradeCoinIcon = styled.div`
  display: flex;
  height: 20%;
  justify-content: end;
  padding: 5px 10px;
`

const ProjectCategory = styled.div`
  position: absolute;
  z-index: 10;
  margin: 5px;
  background: #fff;
  padding: 5px 10px;
  ${GLASS}
  font-weight: bold;
`;

const CardTitleContainer = styled.div`
  color: white;
  font-size: 12px;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
`;

const CardTitle = styled.div`
  width: 100%;
  background: #0000006c;
  font-size: 16px;
  font-weight: bold;
  padding-left: 10px;

  p {
    margin-bottom: 0.5em;
    text-align: left;
  }
`;

const CardFloorInfo = styled.div`
  width: 100%;
  padding-left: 10px;
  text-align: left;
  background: #0000006c;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
`;

const ProjectTradeCoinIconImg = styled.img`
  min-height: 20px;
  max-height: 30px;
  height: 80%;
`;
const ProjectCard = ({ launchpadItem }: LaunchpadProjectCardProps) => {
  return (
    <Link to={`/launchpad/project/${launchpadItem.projectId}`}>
      <CardContainer backgroundUrl={launchpadItem.imgProfileUrl}>
        {launchpadItem.category ? (
          <ProjectCategory>{launchpadItem.category}</ProjectCategory>
        ) : (
          <></>
        )}
        <ProjectTradeCoinIcon>
          <ProjectTradeCoinIconImg src="/img/ico_USDT.png"/>
        </ProjectTradeCoinIcon>
        <CardTitleContainer>
          <CardTitle>
            <p>{launchpadItem.title}</p>
          </CardTitle>
        </CardTitleContainer>
      </CardContainer>
    </Link>
  );
};

export default ProjectCard;
