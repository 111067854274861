import { Col, InputNumber, Modal as AntModal, Row, Select } from "antd";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useRecoilRefresher_UNSTABLE, useRecoilState, useRecoilValue } from "recoil";
import styled from "styled-components";
import { pendingState, walletState } from "../../common/states";
import { castTicker, convertDecimal, getTokenDecimal, sleep } from "../../common/utils/functools";
import { buyNft } from "../../common/utils/market";
import { nftSelector } from "../../common/selector/nft";

const { Option } = Select;

type BuyModalProps = {
  nftItem: NftItem,
  visible: boolean,
  toggleVisible: () => void,
}

const Modal = styled(AntModal)``;

const BuyModal = ({ nftItem, visible, toggleVisible }: BuyModalProps) => {
  const { projectId = "", tokenId = "" } = useParams();
  const walletInfo = useRecoilValue(walletState);
  const [pending, setPending] = useRecoilState(pendingState);
  const refresher = useRecoilRefresher_UNSTABLE(nftSelector({
    projectId,
    tokenId,
  }));

  return <Modal visible={visible} title={`Buy ${nftItem.metadata.name}`} onCancel={() => toggleVisible()} onOk={async () => {
    const res = await buyNft(nftItem, walletInfo);
    if (!res) return;
    setPending(true);
    await sleep(3000);
    setPending(false);
    toggleVisible();
    refresher();
  }}>
    <Row>
      Buy {nftItem.metadata.name} {convertDecimal(nftItem.price, getTokenDecimal(nftItem.ticker))} {castTicker(nftItem.ticker)}?
    </Row>
  </Modal>
}

export default BuyModal;