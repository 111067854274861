import { useRecoilValue } from "recoil"
import { ProjectSearchOptions } from "../../common/selector/project"
import ProjectCard from "../../components/ProjectCard"
import { nftProjectsSelector } from "../../common/selector/project";
import styled from "styled-components";
import Page from "../../components/Page"

type ProjectsProps = {
  searchOptions: ProjectSearchOptions
}

const ProjectsContainer = styled.div`
`

const Collections = styled.div`
  font-size: 3rem;
  text-align: center;
  color: white;
  font-weight: 600;
`

const ProjectCards = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`

const Projects = ({ searchOptions }: ProjectsProps) => {
  const projects = useRecoilValue(nftProjectsSelector({ ...searchOptions }));

  return <div>
    <ProjectCards>
      {projects.map((project) => (
        <ProjectCard project={project} key={project.projectId} />
      ))}
    </ProjectCards>
  </div>
}

export default Projects;