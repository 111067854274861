import axios from "axios";
import { CONFIG } from "../../config";
import { ProjectSearchOptions } from "../selector/project";
import { requester } from "../utils/requester";

export async function getLaunchpadProjects(options: ProjectSearchOptions): Promise<NftLaunchpadItem[]> {
  const res = await requester.get("/launchpad", {
    params: {
      ...options
    }
  });
  return res.data as NftLaunchpadItem[];
}

export async function getLaunchpadProject(projectId: string): Promise<NftLaunchpadItem> {
  const res = await requester.get(`/launchpad/${projectId}`)
  return res.data as NftLaunchpadItem;
}
