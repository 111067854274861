import { fromWei, toWei } from "web3-utils";
import { caver } from "../../caver";

export function ellipseAddress(address: string): string {
  const len = address.length;
  return address.slice(0, 6) + "…" + address.slice(len - 4, len);
}

export function convertDecimal(amount?: string | number, decimal = 18): string {
  if (amount === undefined || amount === null) {
    return "";
  }
  if (decimal === 6) return fromWei(amount.toString(), "mwei");
  return fromWei(amount.toString());
}

export function revConvertDecimal(
  amount?: string | number,
  decimal = 18
): string {
  if (amount === undefined || amount === null) {
    return "";
  }
  if (decimal === 6) return toWei(amount.toString(), "mwei");
  return toWei(amount.toString());
}

export function isAddressEquals(a: string, b: string): boolean {
  return a.toUpperCase() === b.toUpperCase();
}

export function isNftListed(nftItem: NftItem): boolean {
  return nftItem.price !== null && nftItem.price !== "";
}

export function getTokenAddress(token: string | undefined): string {
  if (token === "KLAY") return "0x0000000000000000000000000000000000000000";
  if (token === "OUSDT") return "0xcee8faf64bb97a73bb51e115aa89c17ffa8dd167";
  return "";
}

export function getTokenDecimal(token: string | undefined): number {
  if (token === "OUSDT") return 6;
  return 18;
}

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function numberFormat(num: number) {
  if (num) {
    const dotIndex = num.toString().indexOf(".");
    if (dotIndex > 0) {
      const i = num
        .toString()
        .substr(0, dotIndex)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      const f = num
        .toFixed(2)
        .toString()
        .substr(dotIndex, num.toString().length);
      return i + f;
    } else {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  } else {
    return num;
  }
}

export function toBn(val: string | number) {
  if (val) {
    if (caver.utils.isBN(val)) {
      return val;
    }

    return caver.utils.toBN(val);
  }
  return caver.utils.toBN(0);
}

export function fromPeb(_val: string | number) {
  if (_val) {
    const bn = toBn(_val);
    return caver.utils.convertFromPeb(bn, "KLAY");
  }
  return caver.utils.convertFromPeb(0, "KLAY");
}

export function pebToFloatFormat(
  peb: string | number | undefined,
  precision: number = 18
) {
  if (peb === undefined) {
    return 0;
  }
  const f = fromPeb(peb);
  if (!f) {
    return f;
  }

  const dot = f.indexOf(".");
  if (dot && dot > 0) {
    return f.substr(0, dot + precision + 1);
  } else {
    return f;
  }
}

export function pebToIntegerFormat(peb: string) {
  const f = fromPeb(peb);
  if (!f) {
    return f;
  }

  const dot = f.indexOf(".");
  if (dot && dot > 0) {
    return f.substr(0, dot);
  } else {
    return f;
  }
}

export function convertTimeStampToDate(timestamp: string) {
  const date = new Date(parseInt(timestamp) * 1000);
  return date.toLocaleString();
}

export function castTicker(ticker: string | undefined) {
  if (ticker?.toUpperCase() === "OUSDT") {
    return "oUSDT";
  }
  return ticker?.toUpperCase() || "";
}
