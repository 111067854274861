import { selector } from "recoil"
import { caver } from "../../caver";
import { walletState } from "../states"

export const walletBalanceSelector = selector({
  key: 'walletBalanceSelector',
  get: async ({ get }): Promise<TokenBalance[]> => {
    const { type, address } = get(walletState);
    try {
      if (type === 'kaikas') {
        return [{
          token: 'KLAY',
          balance: await caver.klay.getBalance(address),
          decimal: 18
        }]
      }
    } catch (e) { console.error(e) }
    return [];
  }
});