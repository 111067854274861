import { Select } from "antd";
import Search from "antd/lib/input/Search";
import styled from "styled-components";

const { Option } = Select;

export type UserCollectionSortAndSearchProps = {
  itemCount: number;
  onSearch: (query: string) => void;
  onSort: (sort: SortAndSearchOption) => void;
  searchOption: SortAndSearchOption;
};
export type SortAndSearchOption = {
  order?: string;
  orderBy?: string;
  searchQuery?: string;
};

const SortAndSearchBox = styled.div`
  display: flex;
  justify-content: end;
  width: 70%;
`;
const SearchContainer = styled.div`
  width: 70%;
  max-width: 300px;
`;
const SortContainer = styled.div`
  margin-left: 2rem;
  width: 30%;
  

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    color: white;
    position: relative;
    background-color: transparent;
    border: 1px solid #6b6bd491;
    border-radius: 2px;
    transition: all .3s cubic-bezier(.645,.045,.355,1);
  }

  .ant-select-arrow {
    color: white;
  }
`;
const SortAndSearchContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;
const ItemCountBox = styled.div`
  width: 30%;
  font-size: 3rem;
  color: white;
`
const WrappedSearch = styled(Search)`
  width: 100%;
  .ant-input {
    background-color: transparent;
    border: 1px solid #6b6bd491;
    color: white;
  }

  .ant-btn {
    background-color: transparent;
    border: 1px solid #6b6bd491;
  }

  .ant-input-group-addon {
    background-color: transparent;
  }
`

const UserCollectionSortAndSearch = ({
  onSearch,
  onSort,
  searchOption,
  itemCount,
}: UserCollectionSortAndSearchProps) => {
  return (
    <SortAndSearchContainer>
      <ItemCountBox>
        <span style={{ color: "#3A9CF5", marginRight: "8px" }}>
          {itemCount || 0}
        </span>
        Items
      </ItemCountBox>
      <SortAndSearchBox>
        <SearchContainer>
          <WrappedSearch
            onSearch={(e) => {
              onSearch(e);
            }}
            placeholder={"Please enter the NFT number"}
            enterButton
          />
        </SearchContainer>
        <SortContainer>
          <Select
            defaultValue={"price_usd|asc"}
            style={{ width: "100%" }}
            onChange={(e: string) => {
              const [orderBy, order] = e.split("|");
              onSort({ order, orderBy });
            }}
          >
            <Option value={"price_usd|asc"}>Price low to high</Option>
            <Option value={"price_usd|desc"}>Price high to low</Option>
            <Option value={"created_at|desc"}>Recently Added</Option>
          </Select>
        </SortContainer>
      </SortAndSearchBox>
    </SortAndSearchContainer>
  );
};

export default UserCollectionSortAndSearch;