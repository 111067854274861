import styled from "styled-components";
import { GLASS, NEUMORPH } from "../../common/styles";
import NftBoxTitle from "../../common/NftBoxTitle";
import { Col, Row } from "antd";
import { MintInfoBox, MintInfoTitle } from "../Mint";

type PropertiesProps = {
  nftItem: NftItem;
}

const Container = styled.div`
  border-radius: 20px;
  border: 1px #00B4FF solid;
  color: white;
  padding: 20px;
  margin-bottom: 32px;
`

const Property = styled(Col)`
  text-align: center;
  margin: 10px 0;
`

const Properties = ({ nftItem }: PropertiesProps) => {
  return <Container>
    <NftBoxTitle>Properties</NftBoxTitle>
    <Row>
      {nftItem.metadata.attributes?.map(attr => {
        return <Property span={6}>
          <MintInfoBox>
            <MintInfoTitle>{attr.trait_type}</MintInfoTitle>
            <div>{attr.value}</div>
          </MintInfoBox>
        </Property>
      })}
    </Row>
  </Container>
}

export default Properties;