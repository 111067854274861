import { useRecoilValue } from "recoil";
import styled from "styled-components";
import { nftProjectsSelector } from "../../common/selector/project";
import ProjectCard from "../../components/ProjectCard";

const ProjectsContainer = styled.div`
  width: 100%;
  padding: 0 10%;
  margin-top: 100px;
  text-align: center;
`;

const ProjectsWrapper = styled.div`
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 50px;

  @media only screen and (max-width: 390px) {
    padding: 0;
  }
`;

const Title = styled.div`
  font-family: AppleSDGothicNeo;
  font-size: 48px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: white;
`;

const SubTitle = styled.div`
  margin-top: 10px;
  font-family: AppleSDGothicNeo;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: white;
`;

const Projects = () => {
  const projects = useRecoilValue(nftProjectsSelector({}));

  return (
    <ProjectsContainer>
      <Title>Collections</Title>
      <SubTitle>
        <p style={{marginBottom: "0.3em"}}>YOU, Mr.Everything!</p>
        <p>Enjoy numerous NFT collections in the world.</p>
      </SubTitle>
      <ProjectsWrapper>
        {projects.map((project) => (
          <ProjectCard project={project} key={project.projectId} />
        ))}
      </ProjectsWrapper>
    </ProjectsContainer>
  );
};

export default Projects;
