export const BOXSHADOW = `box-shadow: 0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%), 0 5px 12px 4px rgb(0 0 0 / 9%);`
export const GRAY = `#cbcbd1`
export const BACKGROUND = '#131414e0'
export const GLASS = `
  background: rgba( 255, 255, 255, 0.25 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 4px );
  border-radius: 5px;
`
export const NEUMORPH = `
  -webkit-border-radius: 20px;
  border-radius: 20px;
  background: #f7f8fa;
  -webkit-box-shadow: 12px 12px 24px #d2d3d5, -12px -12px 24px #ffffff;
  box-shadow: 12px 12px 24px #d2d3d5, -12px -12px 24px #ffffff;
`

export const MINT_PROGRESS = `
  -webkit-border-radius: 20px;
  border-radius: 20px;
  background: #f7f8fa;
  -webkit-box-shadow: 12px 12px 24px #d2d3d5, -12px -12px 24px #ffffff;
  box-shadow: 10px 12px 20px 0px #d2d3d5, 0px 1px 24px #ffffff;
`

export const BASIC_BUTTON = `
  border-radius: 10px;
  font-size: 2rem;
  color: #000125;
  border: #000125 2px solid;
  padding: 0.75rem 2.5rem;
  margin-right: 5px;
  width: 20rem;
  background-color: unset;
`