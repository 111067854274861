import React, { useState, useEffect } from "react";
import {
  NavArea,
  NavContainer,
  NavLeft,
  NavRight,
  NavMenu,
  NavProfileMenu,
  NavIcons,
  NavItem,
  NavProfileArea,
  NavItemDisabled,
  MenuTitle,
  NavWalletButton,
  NavProfileButton,
  Label,
  Outline,
  Burger,
  MobileNavWrapper,
  NavCloseOutlined,
  DesktopNavWrapper,
  NavItemContainer,
  NavSubMenu,
  ProfileNavItem,
  UpcomingLabel,
} from "./styles";
import "./index.css";
import { BorderBottomOutlined, MenuOutlined } from "@ant-design/icons";
import { Col, Drawer, Menu, Dropdown } from "antd";
import { useRecoilState, useSetRecoilState } from "recoil";
import { walletState } from "../../common/states";
import { Link, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

const Header = () => {
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [walletStatus, setWalletStatus] = useRecoilState(walletState);
  const toggleDrawer = () => setVisibleDrawer(!visibleDrawer);
  const navigate = useNavigate();
  const [cookie, setCookie] = useCookies(["walletAddress"]);

  useEffect(() => {
    if (cookie.walletAddress) {
      setWalletStatus({
        type: "kaikas",
        address: cookie.walletAddress,
      });
    }
  }, []);

  const ProfileMenus = () => {
    return (
      <NavProfileMenu
        theme="dark"
        items={[
          {
            key: "collection",
            label: (
              <ProfileNavItem
                onClick={(e) => {
                  navigate("/profile/collections", { replace: true });
                }}
              >
                My Collections
              </ProfileNavItem>
            ),
          },
          {
            key: "logout",
            label: (
              <ProfileNavItem
                onClick={(e) => {
                  logOut();
                }}
              >
                Log Out
              </ProfileNavItem>
            ),
          },
        ]}
      />
    );
  };

  const logOut = () => {
    setWalletStatus({
      type: "",
      address: undefined,
    });
    setCookie("walletAddress", "", {
      expires: new Date(Date.now() + 1000 * 60 * 60),
    });
  };

  const NavItems = ({ collapsed }: any) => {
    const scrollTo = (id: string) => {
      const element = document.getElementById(id) as HTMLDivElement;
      setVisibleDrawer(false);
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    };

    const getCurSelected = (): string => {
      return window.location.pathname;
    };
    return (
      <NavItemContainer>
        <Menu
          mode={collapsed ? "inline" : "horizontal"}
          selectedKeys={[getCurSelected()]}
          style={{ background: "transparent", borderBottom: "none" }}
        >
          <NavSubMenu
            key="/nft-market"
            title={"Collections"}
            theme="dark"
            style={{margin:"0 1%"}}
            onTitleClick={(e) => {
              navigate("/nft-market/All", { replace: true });
            }}
          >
            <NavItem
              key="/nft-market/All"
              onClick={(e) => {
                navigate("/nft-market/All", { replace: true });
              }}
            >
              All
            </NavItem>
            <NavItem
              key="/nft-market/PFP"
              onClick={(e) => {
                navigate("/nft-market/PFP", { replace: true });
              }}
            >
              PFP
            </NavItem>
            <NavItem
              key="/nft-market/Gaming"
              onClick={(e) => {
                navigate("/nft-market/Gaming", { replace: true });
              }}
            >
              Gaming
            </NavItem>
            <NavItem
              key="/nft-market/Art"
              onClick={(e) => {
                navigate("/nft-market/Art", { replace: true });
              }}
            >
              Art
            </NavItem>
            <NavItem
              key="/nft-market/Video"
              onClick={(e) => {
                navigate("/nft-market/Video", { replace: true });
              }}
            >
              Video
            </NavItem>
            <NavItem
              key="/nft-market/Sport"
              onClick={(e) => {
                navigate("/nft-market/Sport", { replace: true });
              }}
            >
              Sport
            </NavItem>
            <NavItem
              key="/nft-market/Utility"
              onClick={(e) => {
                navigate("/nft-market/Utility", { replace: true });
              }}
            >
              Utility
            </NavItem>
          </NavSubMenu>
          <NavItem
            key="/launchpad"
            onClick={(e) => {
              navigate("/launchpad/All", { replace: true });
            }}
          >
            Launchpad
          </NavItem>
          <NavItem key="/airdrops" upcoming={true}>
            Airdrops
            <UpcomingLabel src="/img/upcoming.png" />
          </NavItem>
          <NavItem key="/events" upcoming={true}>
            Events
            <UpcomingLabel src="/img/upcoming.png" />
          </NavItem>
        </Menu>
        {!walletStatus.address ? (
          <NavWalletButton
            onClick={async () => {
              if (!window.klaytn) {
                window.open(
                  "https://chrome.google.com/webstore/detail/kaikas/jblndlipeogpafnldhgmapagcccfchpi"
                );
                return;
              }
              const walletAddress: string = (await window.klaytn.enable())[0];
              setCookie("walletAddress", walletAddress, {
                expires: new Date(Date.now() + 1000 * 60 * 60),
              });

              if (walletAddress) {
                setWalletStatus({
                  type: "kaikas",
                  address: walletAddress,
                });
              }
            }}
          >
            <img src={"/img/wallet.png"} />
            Connect Wallet
          </NavWalletButton>
        ) : (
          <NavProfileArea>
            <Dropdown overlay={ProfileMenus} placement="bottom">
              <NavProfileButton
                imageUrl={"/img/profile-default.webp"}
              ></NavProfileButton>
            </Dropdown>
          </NavProfileArea>
        )}
      </NavItemContainer>
    );
  };

  return (
    <NavArea>
      <NavContainer>
        <NavLeft>
          <Link to={"/"}>
            <img
              src="/img/white-logo.png"
              alt="navLogo"
              style={{ width: "210px" }}
            />
          </Link>
        </NavLeft>
        <NavRight>
          <DesktopNavWrapper>
            <NavMenu>
              <NavItems collapsed={false} />
            </NavMenu>
          </DesktopNavWrapper>
          <Burger onClick={toggleDrawer}>
            <Outline />
          </Burger>
          <Drawer
            closable={false}
            visible={visibleDrawer}
            onClose={toggleDrawer}
            drawerStyle={{ backgroundColor: "#111420" }}
          >
            <MobileNavWrapper>
              <Label onClick={toggleDrawer}>
                <NavCloseOutlined />
              </Label>
              <NavItems collapsed={true} />
            </MobileNavWrapper>
          </Drawer>
        </NavRight>
      </NavContainer>
    </NavArea>
  );
};

export default Header;
