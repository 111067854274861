import { Divider, Row } from "antd";
import styled from "styled-components";
import NftBoxTitle from "../../common/NftBoxTitle";
import { checkIsVideo } from "../../common/utils/nft";

type NftDescriptionProps = {
  nftItem: NftItem;
};

const NftDescContainer = styled.div`
  padding: 20px;
  color: white;
`;
const DescriptionContainer = styled.div`
  margin-top: 1rem;
  width: 100%;
`;
const Description = styled.div`
  font-size: 1.5rem;
  color: #999797;
`;
const Picture = styled.img`
  width: 100%;
  max-width: 500px;
  object-fit: contain;
  border-radius: 20px;
`;

const Video = styled.video`
  width: 100%;
  max-width: 500px;
  object-fit: contain;
  border-radius: 20px;
`;

const Name = styled.div`
  width: 100%;
`;

const NftBoxSubTitle = styled.div`
  font-size: 1.5rem;
  font-weight: 700;
  text-align: left;
  color: #cbcbd1;
`;

const NftDescription = ({ nftItem }: NftDescriptionProps) => {
  return (
    <NftDescContainer>
      <Row>
        {checkIsVideo(nftItem.projectId, nftItem.tokenId) ? (
          <Video>
            <source src={nftItem.imgUrl} type="video/mp4" />
          </Video>
        ) : (
          <Picture src={nftItem.imgUrl} />
        )}
      </Row>
      <Row>
        <Divider />
        <DescriptionContainer>
          <NftBoxTitle>Description</NftBoxTitle>
          <Description>{nftItem.metadata.description}</Description>
        </DescriptionContainer>
        <Divider />
      </Row>
    </NftDescContainer>
  );
};

export default NftDescription;
